// @ts-ignore
import GravityFormForm from "gatsby-gravityforms-component";
import React from "react";

import { Fragment } from "react";

import { graphql } from "gatsby";
import { useStaticQuery } from "gatsby";

import Breadcrumbs from "@Js/Component/Breadcrumbs/Breadcrumbs";

import classNames from "@Css/Component/Page/Contact.module.scss";

const StoryPage = () => {
    const result = useStaticQuery(graphql`
        query {
            allGfForm {
                edges {
                    node {
                        ...GravityFormComponent
                    }
                }
            }
        }
    `);

    const breadcrumbs = [
        { text: "Home", url: "/" },
        { text: "Story", url: "/story" }
    ];

    return (
        <Fragment>
            <Breadcrumbs breadcrumbs={ breadcrumbs } />
            <main>
                <section className={ classNames.contact }>
                    <header className={ classNames.header }>
                        <h1 className={ classNames.title }>Story</h1>
                    </header>
                    <GravityFormForm id={ 3 } formData={ result.allGfForm } lambda="https://dutchtechzone.nl/wp-json/dtz/v1/submit-form" />
                </section>
            </main>
        </Fragment>
    );
};

export default StoryPage;
